import React from "react"
import { BrowserRouter } from "react-router-dom"
import { HomeContextProvider } from "../../../state/home/context"
import Home from "../components/Home"
import useStyles from "../styles/Home.styles"


const HomeContainer = () => {
  
  const classes= useStyles()
  

  return (
  <BrowserRouter>
    <div className={classes().homeContainer}>
      <HomeContextProvider>
        <Home />
      </HomeContextProvider>
    </div>
  </BrowserRouter>
)}

export default HomeContainer
