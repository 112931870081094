import React from "react"
import { CssBaseline } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/styles"
import "./styles/app.scss"
import HomeContainer from "../views/home/containers/HomeContainer"
import muiTheme from "./styles/App.styles"

function App() {
  return (
    <div className="app">
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <HomeContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
