import React, { useMemo } from "react"
import useStyles from "../styles/Home.styles"
import Routes from "../../../routes/Routes"
import { useHomeContext } from "../../../state/home/context"

const Home = (props) => {
  const context = useHomeContext()
  const classes = useStyles()
  const { state } = context
  return useMemo(
    () => (<div className={classes.content} id="HomeInnerContainer">
      <Routes menuItems={state.menuItems} />
    </div>), [state.menuItems, classes.content])
}

export default Home
