import { createMuiTheme } from "@material-ui/core/styles"
import UbuntuTTF from './fonts/Ubuntu-Regular.ttf';

const ubuntu = {
  fontFamily: 'Ubuntu',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Ubuntu-Bold'),
    local('Ubuntu-Regular'),
    url(${UbuntuTTF}) format('ttf')
  `
};

const muiTheme = createMuiTheme({
  palette: {
    primary: { main: '#722282' }, // Purple and green play nicely together.
    secondary: { main: '#4FD4C4' }, // This is just green.A700 as hex.
    text: {
      primary: '#494D51'
    },
    background: {
      default: '#FFF'
    }
  },
  typography: {
    fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [ubuntu],
      },
    },
  }
})

export default muiTheme
