import { makeStyles } from "@material-ui/styles"
import {useMediaQuery} from 'react-responsive'

const useStyles = () => { 

  const isMobileWidthLessThan450 = useMediaQuery({maxWidth: '450px'})

  return makeStyles((theme) => ({
      root: {
        width: "100%",
        height: "100%",
        zIndex: 1,
      },
      homeContainer: {
        height: "100vh",
        overflowY: 'auto',
        overflowX: 'hidden'
      },
      appBar: {
        width: "100%",
      },
      appBarContainer: {
        maxWidth: "1150px",
        width: "100%",
        margin:"auto"
      },
      navButton: {
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: 15,
        borderRadius: 20,
        minWidth: isMobileWidthLessThan450 === true? 75 : 120,
        height: isMobileWidthLessThan450 === true? 35 : 'auto',
        '&:hover': {
          backgroundColor: '#FFF'
        },
        "@supports (-webkit-touch-callout: none)": {
          '& span':{
            
          }
        }
      },
      navBar: {
          paddingRight: '4%'
      },
      logo: {
        '& img':{
          width: isMobileWidthLessThan450 === true? '180px !important' : '180px',
        }
      }
      ,
      navItem: {
        color: '#FFF',
        fontWeight: 'bold',
        fontSize: 15,
        minWidth: 120,
        textDecoration: 'none',
        textTransform: 'uppercase'
      },
      content: {
        backgroundColor: theme.palette.background.default,
        width: "100%",
        height: "100%",
        margin: "auto"
      },
      grow: {
        flexGrow: 1
      }
    }))
}

export default useStyles
