import React, { createContext, useContext, useReducer, useCallback } from "react"
import menuItems from "../../assets/config/menu-items.json"
import reducer from "./reducer"

const initialState = {
  menuItems,
  isLoading: false,
}

const HomeContext = createContext()
const { Provider } = HomeContext

const HomeContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  // eslint-disable-next-line react/prop-types
  const { children } = props

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

const useHomeContext = () => {
  const context = useContext(HomeContext)
  const { state } = context
  if (!context) {
    throw new Error(
      "useHomeContext must be used in a component within a HomeContextProvider."
    )
  }
  const goToNext = useCallback(() => {
    return window.open(process.env.REACT_APP_NEXT_SCORESHOTS_URL)
  }, [])
  return { state, goToNext }
}

export { HomeContextProvider, useHomeContext }
