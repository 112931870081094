import React, { lazy, Suspense } from "react"
import { Route, Switch } from "react-router-dom"
import PropTypes from "prop-types"

const Routes = ({ menuItems }) => {
  const routes = menuItems.map((mi) => {
    const component = lazy(() => import(`../views/${mi.component}`))
    return <Route path={mi.url} exact component={component} key={mi.id} />
  })
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>{routes.map((r) => r)}</Switch>
    </Suspense>
  )
}

Routes.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
}
Routes.defaultProps = {
  menuItems: [],
}

export default Routes
