import { GET_MENU_ITEMS } from "./constants"

const reducer = (state, { type }) => {
  switch (type) {
    case GET_MENU_ITEMS:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

export default reducer
